// # [GLOBALIZED VARIABLES]
$color: var(--color);
$color-prm: var(--color-prm);
$color-i: var(--color-i);

$primary: var(--primary);
$secondary: var(--secondary);

$success: var(--success);
$info: var(--info);
$warn: var(--warn);
$danger: var(--danger);

$background-color: var(--background-color);
$background-accent: var(--background-accent);

$shadow-color: var(--shadow-color);

$default: var(--tone2);
$disabled: var(--tone1);

$tone0: var(--tone0);
$tone1: var(--tone1);
$tone2: var(--tone2);
$tone3: var(--tone3);

$twitter: var(--twitter);
$kofi: var(--kofi);
$discord: var(--discord);
$instagram: var(--instagram);
$deviant: var(--deviant);

// # [BASE THEME]
:root {
    // text colors
    --color: #ffffff;
    --color-prm: #ffffff;
    --color-i: #000000;

    // accent colors
    --primary: #1282e4;
    --secondary: #8c45ff;

    --success: #16c13b;
    --info: #1282e4;
    --warn: #a57f17;
    --danger: #ce2626;

    // background colors
    --background-color: #0f182c;
    --background-accent: #141f36;

    // miscellaneous
    --shadow-color: #060c1b;

    --tone0: #25324f;
    --tone1: #333946;
    --tone2: #525d72;
    --tone3: #7684a2;

    // companies
    --twitter: #1DA1F2;
    --kofi: #7644eb;
    --discord: #5865F2;
    --instagram: #d62976;
    --deviant: #05CC46;
}

// # [OTHER THEMES]
[data-theme="dark"] {
    // # duplicate root variables you want to set here

    --color: #000000;
    --color-i: #ffffff;
}
