@import "../../index.scss";

.container {
	@include wight(100%, 100%);
	@include flex-center;
	flex-direction: column;
	background: radial-gradient(ellipse at top, #6895ff17 0%, #004cff12 20%, #004cff00 50%);

	font-weight: bolder;
	text-shadow: 0 0 30px #004cff46;

	svg {
		filter: drop-shadow(0 0 0.75rem #004cff54);
		
		position: relative;
		width: 120px;
		animation: fadeIn 1s cubic-bezier(.31,.23,.26,1.01) forwards;
		opacity: 0;
	}

	.content {
		@include flex-center;
		position: relative;

		h2 {
			@include flex-center;
			flex-direction: row;
			gap: 10px;
		}

		animation: fadeIn 1s 0.15s cubic-bezier(.31,.23,.26,1.01) forwards;
		opacity: 0;
	}

	.innerCard {
		@include flex-center;
		@include card;

		position: relative;
		background-color: unset;
		border: 1px solid $background-accent;

		flex-direction: column;
		gap: 20px;
		padding: 40px 80px;

		box-shadow: 0 0 50px #004cff1a;

		animation: fadeIn 1s 0.25s cubic-bezier(.31,.23,.26,1.01) forwards;
		opacity: 0;
	}
}

@keyframes fadeIn {
	from {
		bottom: -30px;
		opacity: 0;
	}
	
	to {
		bottom: 0;
		opacity: 1;
	}
}

@media only screen and (max-width: 430px) {
	.container {

		h1 {
			font-size: 2.3rem;
		}
		
		h2 {
			font-size: 1.3rem;
		}

		.innerCard {
			margin-top: 50px;
			padding: 40px 20px;
		}
	}
}
