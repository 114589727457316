@import '../../../index.scss';

$_internal-padding: 15px;

.modal {
	@include flex-center;

	inset: 0;
	position: fixed;
	pointer-events: all;
	z-index: 10000;

	.modalWrapper {
		@include mar-pad(0, 0);

		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 500px;
		border-radius: $global-border-radius;
		border: $tone1 1px solid;
		background-color: $background-color;
		z-index: 1000;
		cursor: default;

		// add format here for header or whatever
		.modalHeader {
			@include flex-center;
			padding: $_internal-padding;
			justify-content: space-between;
			border-bottom: $tone1 1px solid;

			h3 {
				margin: 0;
			}

			button {
				@include wight(38px, 38px);
				display: inline-flex;
				border: none;
				color: $color;
				background-color: transparent;

				pointer-events: all;
				cursor: pointer;
			}
		}
		.modalContainer {
			padding: $_internal-padding;
		}
		.modalAction {
			display: flex;
			flex-direction: row;
			justify-content: end;
			padding: $_internal-padding;
			border-top: $tone1 1px solid;
			gap: 10px;
		}
	}

	.modalBackground {
		@include flex-center;
		@include wight(100vw, 100vh);

		position: absolute;
		background-color: $shadow-color;
		filter: opacity(0.5);
	}
}